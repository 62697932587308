.home-main-btn {
  margin-bottom: 1.2rem;
}

@media screen and (max-width: 600px) {
  .home-main-btn {
    width: calc(100% / 3);
  }
}

@media screen and (min-width: 601px) {
  .home-main-btn {
    width: calc(100% / 4);
    height: calc(10%);
  }
}

@media screen and (min-width: 1025px) {
  .home-main-btn {
    width: calc(100% / 5);
    height: calc(10%);
  }
}

@media screen and (min-width: 1500px) {
  .home-main-btn {
    width: calc(100% / 6);
    height: calc(10%);
  }
}

@media screen and (min-width: 1600px) {
  .home-main-btn {
    width: calc(100% / 7);
    height: calc(10%);
  }
}
