/* 645 ball */
.ball_645 {
  display: inline-block;
  border-radius: 100%;
  text-align: center;
  vertical-align: middle;
  color: #fff;
  font-weight: 500; /* text-shadow: 0px 0px 2px rgba(0, 0, 0, 1); */
}
.ball_645.lrg {
  width: 40px;
  height: 40px;
  line-height: 38px;
  font-size: 20px;
}
.ball_645.mid {
  width: 27px;
  height: 27px;
  line-height: 24px;
  font-size: 17px;
}
.ball_645.sml {
  width: 22px;
  height: 22px;
  line-height: 19px;
  font-size: 15px;
}
/* .ball_645.sml {
  width: 24px;
  height: 24px;
  line-height: 22px;
  font-size: 13px;
} */
.ball_645.not {
  color: #777;
}
.ball_645.sml.not {
  font-weight: 300;
}
.ball_645.ball1 {
  background: #fbc400;
  text-shadow: 0px 0px 3px rgba(73, 57, 0, 0.8);
}
.ball_645.ball2 {
  background: #69c8f2;
  text-shadow: 0px 0px 3px rgba(0, 49, 70, 0.8);
}
.ball_645.ball3 {
  background: #ff7272;
  text-shadow: 0px 0px 3px rgba(64, 0, 0, 0.8);
}
.ball_645.ball4 {
  background: #aaa;
  text-shadow: 0px 0px 3px rgba(61, 61, 61, 0.8);
}
.ball_645.ball5 {
  background: #b0d840;
  text-shadow: 0px 0px 3px rgba(41, 56, 0, 0.8);
}
table tr td .ball_645.sml {
  margin: 0 3px;
}

/* 720 ball */
.ball_645.ball6 {
  background: #c7c7c7;
  text-shadow: 0px 0px 3px rgba(73, 57, 0, 0.8);
}
.ball_645.ball7 {
  background: #ff4c4c;
  text-shadow: 0px 0px 3px rgba(0, 49, 70, 0.8);
}
.ball_645.ball8 {
  background: #ff8e4f;
  text-shadow: 0px 0px 3px rgba(64, 0, 0, 0.8);
}
.ball_645.ball9 {
  background: #fcd153;
  text-shadow: 0px 0px 3px rgba(61, 61, 61, 0.8);
}
.ball_645.ball10 {
  background: #3cbcff;
  text-shadow: 0px 0px 3px rgba(41, 56, 0, 0.8);
}
.ball_645.ball11 {
  background: #8d70da;
  text-shadow: 0px 0px 3px rgba(41, 56, 0, 0.8);
}
.ball_645.ball12 {
  background: #777777;
  text-shadow: 0px 0px 3px rgba(41, 56, 0, 0.8);
}
