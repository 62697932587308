.thumb {
  width: 100%;
  max-height: 316px;
}

img {
  vertical-align: middle;
}

.area_thumb {
  overflow: hidden;
  position: relative;
  text-align: center;
}

.item {
  margin: 25px 25px 0 0;
  display: inline-block;
  vertical-align: top;
  list-style: none;
}
.area_text {
  text-align: center;
  font-size: 10;
}
