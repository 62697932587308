.w3-content {
  width: 100%;
  height: 100%;
  border: 1px solid #000000;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: black;
  text-decoration: none;
}

.link-cursor {
  cursor: pointer;
}
