/* 가운데 위치 및 배경  */
.popup-bg-layer {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 10;
}
